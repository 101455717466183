import React from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { UserOutlined, PoweroffOutlined } from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import logo from '../../assets/logo.png'
import { useAuth } from "../Auth/AuthContext"


const { Header, Content, Footer, Sider } = Layout;

const navUser = [
  {
    key: "/dashboard",
    label: "Dashboard",
  },
  {
    key: "#",
    label: "Sales",
    children: [
      {
        key: "/sales",
        label: "List",
      },
      {
        key: "/refunds",
        label: "Refunds",
      }
    ],
  },
  {
    key: "/boosts",
    label: "Boosts",
  },
  {
    key: "/sizes",
    label: "Sizes",
  },
  {
    key: "/machines",
    label: "Machines",
  }
]

const navAdmin = [
  {
    key: "/dashboard",
    label: "Dashboard",
  },
  {
    key: "#",
    label: "Sales",
    children: [
      {
        key: "/sales",
        label: "List",
      },
      {
        key: "/refunds",
        label: "Refunds",
      }
    ],
  },
  {
    key: "/boosts",
    label: "Boosts",
  },
  {
    key: "/sizes",
    label: "Sizes",
  },
  {
    key: "/machines",
    label: "Machines",
  },
  {
    key: "/users",
    label: "Users",
  },
  {
    key: "/offline-machines",
    label: "Offline Machines",
  },
]



const ProtectedLayout = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  
  const userLevel = localStorage.getItem("userLevel")
  const SideNavItems = userLevel ==1 ? navAdmin : navUser;
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const activeKey =
    SideNavItems.find((item) => item.key === currentPath)?.key || "/";
    
  const { logout, userName } = useAuth()


  const TopNavItems = [
    {
      icon: <UserOutlined />,
      label: userName,
      children: [
        {
          key: "/account",
          label: "Account",
        },
        {
          key: "/password-reset",
          label: "Reset Password",
        },
        {
          key: "SignOut",
          label: "Sign Out",
          icon: <PoweroffOutlined />,
          danger: true,
        },
      ],
    },
  ]

  return (
    <>
      <Layout style={{minHeight:"100vh"}}>
        <Sider
          theme="dark"
          breakpoint="md"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          width={280}
        >
          <div
            style={{
              height: 80,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="DrinkUp"
              src={logo}
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[activeKey]}
            items={SideNavItems}
            onClick={({ key }) => {
              navigate(key);
            }}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          >
            <Menu
              theme="light"
              mode="horizontal"
              defaultSelectedKeys={["2"]}
              items={TopNavItems}
              style={{
                flex: 1,
                minWidth: 0,
                position: "relative",
                display: "flex",
                justifyContent: "right",
              }}
              onClick={({ key }) => {
                if (key === "SignOut") {
                  //sign out method
                  logout()
                }else if(key==="#"){
                  //do nothing
                } else {
                  navigate(key);
                }
              }}
            />
          </Header>
              <Outlet />
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            Drinkup ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </>
  );
};

export default ProtectedLayout;
