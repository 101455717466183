import React, { useEffect, useState } from "react"
import { Table, Col, Row, Button, Spin, Space, Popconfirm, Tag } from "antd"
import * as config from "../../Config/config"

import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import PageLayout from "../Layouts/PageLayout"

const Index = () => {
  const navigate = useNavigate()
  const [datalist, setDataList] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const userLevel = localStorage.getItem("userLevel");

  const LoadData = () => {
    setIsLoading(true)
    config
      .GET_Req(config.GET_USERS, {})
      .then(function (response) {
        console.log(response)
        setDataList(response)
        setIsLoading(false)
      })
      .catch(function (err) {
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const DeleteItem = (itemId) => {
    setIsLoading(true)
    config
      .DELETE_Req(`${config.DELETE_USER}/${itemId}`, {})
      .then(function (response) {
        console.log(response)
        LoadData()
        setIsLoading(false)
      })
      .catch(function (err) {
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    let isMounted = true

    LoadData()

    return () => {
      isMounted = false
    }
  }, [])

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      //   render: (text, record) => {
      //     // Assuming price is stored in cents, dividing by 100 to display in dollars
      //     const priceInDollars = record.price / 100
      //     return <span>${priceInDollars}</span>
      //   },
    },
    {
      title: "Active",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (text, record) => {
        return (
          <Space size="middle">
            {text ? (
              <Tag color="success">Active</Tag>
            ) : (
              <Tag color="error">Disabled</Tag>
            )}
          </Space>
        )
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button onClick={() => navigate(`/users/edit/${record.email}`)}>
              Edit
            </Button>
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this record?"
              onConfirm={() => DeleteItem(record.userId)}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  
  return (
    <>
      <PageLayout>
        <Row>
          <h1>Users</h1>
        </Row>

        <hr></hr>

        {/* Loading */}
        {isLoading ? (
          <Row>
            <Col
              span={24}
              style={{
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 50,
                    }}
                    spin
                  />
                }
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col
                span={24}
                style={{
                  padding: 10,
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "right",
                }}
              >
                <Button color="success" onClick={() => navigate(`/users/add`)}>
                  {" "}
                  Add New
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table dataSource={datalist} columns={columns} rowKey="id" />
              </Col>
            </Row>
          </>
        )}
      </PageLayout>
    </>
  )
}

export default Index
