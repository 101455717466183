import { theme } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";

const PageLayout = ({ children }) => {
    const {
      token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    
  return (
    <Content
      style={{
        margin: "0px 4px 0",
      }}
    >
      <div
        style={{
          padding: 24,
          flex: 1,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        {children}
      </div>
    </Content>
  );
};

export default PageLayout;
