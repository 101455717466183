import React from "react"
import { Route, Routes } from "react-router-dom"
//Pages
import LoginPage from "./Pages/LoginPage"
import DashboardPage from "./Pages/DashboardPage"
import AccountPage from "./Pages/AccountPage"
import PasswordResetPage from "./Pages/AccountPage/password-reset"
import BoostPage from "./Pages/BoostPage"
import BoostAddPage from "./Pages/BoostPage/add"
import BoostEditPage from "./Pages/BoostPage/edit"
import SizePage from "./Pages/SizePage"
import SizeAddPage from "./Pages/SizePage/add"
import SizeEditPage from "./Pages/SizePage/edit"
import MachinePage from "./Pages/MachinePage"
import MachineAddPage from "./Pages/MachinePage/add"
import MachineEditPage from "./Pages/MachinePage/edit"
import SalesPage from "./Pages/SalesPage"
import UsersPage from "./Pages/Users"
import UsersAddPage from "./Pages/Users/add"
import UsersEditPage from "./Pages/Users/edit"
import OfflineMachinesPage from "./Pages/OfflineMachines"

// Layouts
import ProtectedLayout from "./Pages/Layouts/ProtectedLayout"
import AuthLayout from "./Pages/Layouts/AuthLayout"

import { AuthProvider } from "./Pages/Auth/AuthContext"
import PrivateRoute from "./Pages/Auth/PrivateRoute"

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<LoginPage />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route element={<ProtectedLayout />}>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/password-reset" element={<PasswordResetPage />} />
            <Route path="/boosts" element={<BoostPage />} />
            <Route path="/boosts/edit/:BoostId" element={<BoostEditPage />} />
            <Route path="/boosts/add" element={<BoostAddPage />} />
            <Route path="/sizes" element={<SizePage />} />
            <Route path="/sizes/add" element={<SizeAddPage />} />
            <Route path="/sizes/edit/:SizeId" element={<SizeEditPage />} />
            <Route path="/machines" element={<MachinePage />} />
            <Route path="/machines/add" element={<MachineAddPage />} />
            <Route
              path="/machines/edit/:MachineId/:tabId"
              element={<MachineEditPage />}
            />
            <Route path="/sales" element={<SalesPage />} />
            <Route path="/refunds" element={<SalesPage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/users/add" element={<UsersAddPage />} />
            <Route path="/users/edit/:UserEmail" element={<UsersEditPage />} />
            <Route path="/offline-machines" element={<OfflineMachinesPage />} />

          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  )
}
export default App
