import React, { useEffect, useState } from "react";
import { Col, Row, Button, Spin, Checkbox } from "antd";
import * as config from "../../Config/config";
// import { Form, FormGroup, Input, Label } from "reactstrap";
import { Form, Input, Upload } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PageLayout from "../Layouts/PageLayout";

const Index = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => {
      setIsMounted(false); // Set isMounted to false when component unmounts
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      title: "",
      subTitle: "",
      price: 0,
      duration: 0,
      percentage: 0,
      ingredients: "",
      comment: "",
      target: 0,
      details: "",
      enabled: false,
      image: null,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      subTitle: Yup.string().required("Sub Title is required"),
      price: Yup.number().required("Price is required"),
      ingredients: Yup.string().required("Ingredients is required"),
      comment: Yup.string(),
      target: Yup.number().required("Sort No is required"),
      details: Yup.string().required("Description is required"),
      image: Yup.mixed()
      .nullable()
        .test(
          "image",
          "Image is required",
          (value) => {
            return !!value; // Pass validation if value is truthy (not null or undefined)
          }
        ).test(
        "fileSize",
        "Image must be less than or equal to 300x300 pixels",
        (value) => {
          if (!value) return true; // No image provided, validation will be handled by the "required" rule
          return new Promise((resolve) => {
            const image = new Image();
            image.onload = () => {
              if (image.width <= 300 && image.height <= 300) {
                resolve(true);
              } else {
                resolve(false);
              }
            };
            image.src = URL.createObjectURL(value);
          });
        }
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // Handle form submission here
        console.log(values);

        const response = await uploadImageFile(values);

        const params = {
          id: values.id,
          title: values.title,
          subTitle: values.subTitle,
          price: parseInt(values.price * 100),
          duration: parseInt(values.duration),
          percentage: parseInt(values.percentage),
          ingredients: values.ingredients,
          details: values.details,
          enabled: values.enabled,
          mediaId: response.mediaID,
          comment: values.comment,
          target: parseInt(values.target),
        };
        
        SaveData(params)


      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        // Make sure to set submitting to false, if applicable
        setSubmitting(false);
      }
    },
  });


  const SaveData = (payload)=>{
    if (!isMounted) return;

    
    setIsLoading(true);
    config
      .POST_Req(config.ADD_BOOSTS, payload)
      .then(function (response) {
        console.log(response);
        setIsLoading(false);
        config.openNotification('success','Record saved successfully')
        navigate('/boosts')
      })
      .catch(function (err) {
        setIsLoading(false);
      })
  }

  const uploadImageFile = async (values) => {
    if (!isMounted) return;

    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("File", values.image);
      formData.append("Page", "Boosts");
      formData.append("Title", values.title);
      formData.append("ID", "0");

      const response = await axios.post(`${config.UPLOAD_BOOSTS}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);
      setIsUploading(false);
      return response.data;
    } catch (error) {
      setIsUploading(false);
      config.openNotification("error", "Image Upload Failed");
      throw error;
    }
  };

  const handleFileUpload = (file) => {
    if (!isMounted) return;
    const fileSize = file.size / 1024 / 1024; // in MB
    if (fileSize > 2) {
      // message.error("Image must be less than 2MB");
      config.openNotification(
        "error",
        "Upload Error",
        "Image must be less than 2MB"
      );
      return false;
    }
    formik.setFieldValue("image", file);
    return false; // Prevent upload
  };

  return (
    <>
    
    <PageLayout>
      <Row>
        <Col span={12}>
          <h1>Add Boost</h1>
        </Col>
        <Col
          span={12}
          style={{
            padding: 10,
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
          }}
        >
          <Button onClick={() => navigate("/boosts")}>Back</Button>
        </Col>
      </Row>
      <hr></hr>
      {/* Loading */}
      {isLoading ? (
        <Row>
          <Col
            span={24}
            style={{
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 50,
                  }}
                  spin
                />
              }
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col span={20}>
              <Form onFinish={formik.handleSubmit}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Title *"
                      validateStatus={
                        formik.errors.title && formik.touched.title
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.title && formik.touched.title
                          ? formik.errors.title
                          : ""
                      }
                    >
                      <Input
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="nope"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Sub Title *"
                      validateStatus={
                        formik.errors.subTitle && formik.touched.subTitle
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.subTitle && formik.touched.subTitle
                          ? formik.errors.subTitle
                          : ""
                      }
                    >
                      <Input
                        name="subTitle"
                        value={formik.values.subTitle}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="nope"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Price ($) *"
                      validateStatus={
                        formik.errors.price && formik.touched.price
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.price && formik.touched.price
                          ? formik.errors.price
                          : ""
                      }
                    >
                      <Input
                        name="price"
                        type="number"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Ingredients *"
                      validateStatus={
                        formik.errors.ingredients && formik.touched.ingredients
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.ingredients && formik.touched.ingredients
                          ? formik.errors.ingredients
                          : ""
                      }
                    >
                      <Input
                        name="ingredients"
                        value={formik.values.ingredients}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Note / Comments"
                      validateStatus={
                        formik.errors.comment && formik.touched.comment
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.comment && formik.touched.comment
                          ? formik.errors.comment
                          : ""
                      }
                    >
                      <Input.TextArea
                        name="comment"
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Sort No"
                      validateStatus={
                        formik.errors.target && formik.touched.target
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.target && formik.touched.target
                          ? formik.errors.target
                          : ""
                      }
                    >
                      <Input
                        name="target"
                        type="number"
                        value={formik.values.target}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Description *"
                      validateStatus={
                        formik.errors.details && formik.touched.details
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.details && formik.touched.details
                          ? formik.errors.details
                          : ""
                      }
                    >
                      <Input.TextArea
                        rows={4}
                        name="details"
                        value={formik.values.details}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Select Image (png)*"
                      validateStatus={
                        formik.errors.image && formik.touched.image
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.image && formik.touched.image
                          ? formik.errors.image
                          : ""
                      }
                    >
                      <Upload
                        name="file"
                        accept="image/png"
                        beforeUpload={handleFileUpload}
                        listType="picture-card"
                        showUploadList={false}
                        className="avatar-uploader"
                        
                      >
                        {formik.values.image ? (
                          <img
                            src={URL.createObjectURL(formik.values.image)}
                            alt="Uploaded"
                            style={{
                              height: "100%",
                            }}
                          />
                        ) : (
                          <button
                            style={{
                              border: 0,
                              background: "none",
                            }}
                            type="button"
                          >
                            {isUploading ? (
                              <LoadingOutlined />
                            ) : (
                              <PlusOutlined />
                            )}
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Upload
                            </div>
                          </button>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Enable">
                      <Checkbox
                        name="enabled"
                        checked={formik.values.enabled}
                        onChange={formik.handleChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                >
                  Submit
                </Button>
              </Form>

              {/* <Form>
                <FormGroup>
                  <Label for="exampleEmail">Email</Label>
                  <Input
                    id="exampleEmail"
                    name="email"
                    placeholder="with a placeholder"
                    type="email"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword">Password</Label>
                  <Input
                    id="examplePassword"
                    name="password"
                    placeholder="password placeholder"
                    type="password"
                  />
                </FormGroup>
                <Button type="primary">Save</Button>
              </Form> */}
            </Col>
          </Row>
        </>
      )}
      </PageLayout>
    </>
  );
};

export default Index;
