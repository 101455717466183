import React, { createContext, useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    userName: null,
    userId:null,
    userLevel: null
  })

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName")
    const storedUserId = localStorage.getItem("userId")
    const storedUserLevel = localStorage.getItem("userLevel")
    if (storedUserName && storedUserId && storedUserLevel) {
      setAuthState({
        isAuthenticated: true,
        userName: storedUserName,
        userId: storedUserId,
        userLevel: storedUserLevel
      })
    }
  }, [])

  const navigate = useNavigate()

  const login = (userName, userId, userLevel) => {
    setAuthState({
      isAuthenticated: true,
      userName,
      userId,
      userLevel
    })
    localStorage.setItem("userName", userName)
    localStorage.setItem("userId", userId)
    localStorage.setItem("userLevel", userLevel)
    navigate("/dashboard")
  }

  const logout = () => {
    setAuthState({
      isAuthenticated: false,
      userName: null,
      userId:null,
      userLevel: null
    })
    localStorage.removeItem("userName")
    localStorage.removeItem("userId")
    localStorage.removeItem("userLevel")
    navigate("/")
  }

  return (
    <AuthContext.Provider value={{ ...authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
