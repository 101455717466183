import React, { useEffect, useState } from "react";
import { Col, Row, Button, Spin, Checkbox, Form, Input, Tooltip } from "antd";
import * as config from "../../Config/config";
// import { Form, FormGroup, Input, Label } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineQuestionCircle  } from "react-icons/ai";
import PageLayout from "../Layouts/PageLayout";


const Index = () => {
  const navigate = useNavigate();
  const { UserEmail } = useParams();
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [activeRoles, setActiveRoles] = useState([]);

  useEffect(() => {
    LoadUserRoles();
    LoadData();
    return () => {
      setIsMounted(false);
    };
  }, [UserEmail, isMounted]);

  const LoadData = async () => {
    if (!isMounted) return;

    setIsLoading(true);
    try {
      const userId = localStorage.getItem("userId")
      const response = await config.GET_Req(
        `${config.GET_USER_BY_ID}/${userId}`,
        {}
      );
      console.log(response);
      setUserData(response);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const LoadUserRoles = async () => {
    // Assume this function loads user roles
    // setActiveRoles(await fetchRoles());

    if (!isMounted) return;

    // setIsLoading(true);
    config
      .GET_Req(`${config.GET_USER_ROLES}`, {})
      .then(function (response) {
        console.log(response);
        setActiveRoles(response);
        // setIsLoading(false);
      })
      .catch(function (err) {
        // setIsLoading(false);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userData?.name || "",
      email: userData?.email || "",
      address: userData?.address || "",
      mobile: userData?.phoneNumber || "",
      password: "",
      password_confirm: "",
      city: userData?.city || "",
      state: userData?.state || "",
      zip: userData?.zipCode || "",
      enabled: userData?.isEnabled || true,
      roles: userData?.roles || [], // assuming roles is an array of selected role IDs
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      roles: Yup.array()
        .min(1, "Role is required")
        .required("Role is required"),

      password: Yup.string()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
          "Password must contain atleast 1 lowercase, 1 uppercase, 1 number and 1 special character."
        )
        .min(8, "Password must have atleast 8 Charaters."),
      password_confirm: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const params = {
          userId: userData?.userId,
          name: values.name,
          email: values.email,
          password: values?.password == ""? "" : values?.password,
          phoneNumber: values.mobile,
          address: values.address,
          city: values.city,
          state: values.state,
          zipCode: values.zip,
          roles: values.roles,
          isenabled: values.enabled,
          createdby: 1,
        };
        console.log(params);
        await SaveData(params);
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const SaveData = (payload) => {
    if (!isMounted) return;

    setIsLoading(true);
    config
      .POST_Req(config.USER_ADD, payload)
      .then(function (response) {
        console.log(response);
        setIsLoading(false);
        if (response.status) {
          config.openNotification("success", "Record saved successfully");
        } else {
          config.openNotification("error", `Error`, response.message);
        }
      })
      .catch(function (err) {
        setIsLoading(false);
      });
  };

  function generateRandomPassword(length) {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%?";
    let password = "";

    while (password.length < length) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    // Ensure the password meets the criteria
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}|[\]\\:";'<>?,./]/.test(password);

    if (hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar) {
      return password;
    } else {
      // If criteria not met, recursively generate a new password
      return generateRandomPassword(length);
    }
  }

  const generatePassword = () => {
    const pass = generateRandomPassword(8);

    formik.setValues({
      ...formik.values,
      password: pass,
      password_confirm: pass,
    });
  };

  return (
    <>
      <PageLayout>
        <Row>
          <Col span={12}>
            <h1>Reset Password</h1>
          </Col>
          <Col
            span={12}
            style={{
              padding: 10,
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
            }}
          >
          </Col>
        </Row>
        <hr></hr>
        {/* Loading */}
        {isLoading ? (
          <Row>
            <Col
              span={24}
              style={{
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 50,
                    }}
                    spin
                  />
                }
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col span={20}>
                <Form onFinish={formik.handleSubmit}>
                  <Form.Item
                    label={(
                      <span>
                        Password&nbsp;
                        <Tooltip title="Leave the password fields empty if you do not wish to change your password.">
                          <AiOutlineQuestionCircle />
                        </Tooltip>
                      </span>
                    )}
                    validateStatus={
                      formik.errors.password && formik.touched.password
                        ? "error"
                        : ""
                    }
                    help={
                      formik.errors.password && formik.touched.password
                        ? formik.errors.password
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input.Password
                      name="password"
                      maxLength={30}
                      autoComplete="off"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label={(
                      <span>
                        Confirm Password&nbsp;
                        <Tooltip title="Leave the password fields empty if you do not wish to change your password.">
                          <AiOutlineQuestionCircle />
                        </Tooltip>
                      </span>
                    )}
                    validateStatus={
                      formik.errors.password_confirm &&
                      formik.touched.password_confirm
                        ? "error"
                        : ""
                    }
                    help={
                      formik.errors.password_confirm &&
                      formik.touched.password_confirm
                        ? formik.errors.password_confirm
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input.Password
                      name="password_confirm"
                      maxLength={30}
                      autoComplete="off"
                      value={formik.values.password_confirm}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </PageLayout>
    </>
  );
};

export default Index;