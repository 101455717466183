import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Spin,
  Checkbox,
  Form,
  Input,
  Select,
  Badge,
  Dropdown,
  Space,
  Table,
  Flex,
  InputNumber,
  Alert,
} from "antd";
import * as config from "../../../Config/config";
// import { Form, FormGroup, Input, Label } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import { LoadingOutlined, CopyOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

const EditRecipes = () => {
  const { MachineId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  const [recipes, setRecipes] = useState();
  const [sizeBoostData, setSizeBoostData] = useState();

  const [currentRecipe, setCurrentRecipe] = useState();
  const [currentRecipeTitle, setCurrentRecipeTitle] = useState();

  const [recipeRes, setRecipeRes] = useState();

  useEffect(() => {
    LoadSizesBoosts();

    return () => {
      setIsMounted(false); // Set isMounted to false when component unmounts
    };
  }, []);

  const LoadSizesBoosts = () => {
    setIsLoading(true);
    config
      .GET_Req(`${config.GET_BOOSTSIZES_BY_MACHINE}/${MachineId}`, {})
      .then(function (response) {
        console.log("sizeBoostData: ", response);
        setSizeBoostData(response);
        LoadRecipes();
        setIsLoading(false);
      })
      .catch(function (err) {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const LoadRecipes = () => {
    setIsLoading(true);
    config
      .POST_Req(`${config.GET_RECIPE}`, {
        id: MachineId,
      })
      .then(function (response) {
        var parsedRecipe = JSON.parse(response.recipe_Data);
        console.log(response);
        console.log(parsedRecipe.data.sizes);
        setRecipes(parsedRecipe.data.sizes);
        setRecipeRes(response);

        //select the first size

        const firstItem = Object.keys(parsedRecipe.data.sizes)[0];
        setCurrentRecipeTitle(firstItem);
        setCurrentRecipe(parsedRecipe.data.sizes[firstItem]);

        // setCurrentRecipe()
        setIsLoading(false);
      })
      .catch(function (err) {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const recipeChangePressed = (title) => {
    console.log(title, recipes[title]);
    setCurrentRecipeTitle(title);
    setCurrentRecipe(recipes[title]);

    //resets the current recipe | TODO
  };

  const SaveRecipe = (payload) => {
    setIsLoading(true);
    config
      .POST_Req(`${config.UPDATE_RECIPE}`, payload)
      .then(function (response) {
        console.log(response);
        LoadRecipes();
        setIsLoading(false);
      })
      .catch(function (err) {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    // Water: Yup.number().min(0).max(500).required("Required"),
    // p1Boost: Yup.number().min(0).max(10).required("Required"),
    // p1WaterDelay: Yup.number().min(0).max(10).required("Required"),
    // p1WaterOn: Yup.number().min(0).max(10).required("Required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Water: currentRecipe?.Water || 0,
      p1Boost: currentRecipe?.boosts["b.pump 1"]?.BON || 1,
      p1WaterDelay: currentRecipe?.boosts["b.pump 1"]?.WD || 1,
      p1WaterOn: currentRecipe?.boosts["b.pump 1"]?.WON || 1,
      // ----
      p2Boost: currentRecipe?.boosts["b.pump 2"]?.BON || 1,
      p2WaterDelay: currentRecipe?.boosts["b.pump 2"]?.WD || 1,
      p2WaterOn: currentRecipe?.boosts["b.pump 2"]?.WON || 1,
      // ----
      p3Boost: currentRecipe?.boosts["b.pump 3"]?.BON || 1,
      p3WaterDelay: currentRecipe?.boosts["b.pump 3"]?.WD || 1,
      p3WaterOn: currentRecipe?.boosts["b.pump 3"]?.WON || 1,
      // ----
      p4Boost: currentRecipe?.boosts["b.pump 4"]?.BON || 1,
      p4WaterDelay: currentRecipe?.boosts["b.pump 4"]?.WD || 1,
      p4WaterOn: currentRecipe?.boosts["b.pump 4"]?.WON || 1,
      // ----
      p5Boost: currentRecipe?.boosts["b.pump 5"]?.BON || 1,
      p5WaterDelay: currentRecipe?.boosts["b.pump 5"]?.WD || 1,
      p5WaterOn: currentRecipe?.boosts["b.pump 5"]?.WON || 1,
    },
    // validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const recipeObject = {
          [currentRecipeTitle]: {
            boosts: {
              "b.pump 1": {
                BON: String(values.p1Boost),
                WD: String(values.p1WaterDelay),
                WON: String(values.p1WaterOn),
              },
              "b.pump 2": {
                BON: String(values.p2Boost),
                WD: String(values.p2WaterDelay),
                WON: String(values.p2WaterOn),
              },
              "b.pump 3": {
                BON: String(values.p3Boost),
                WD: String(values.p3WaterDelay),
                WON: String(values.p3WaterOn),
              },
              "b.pump 4": {
                BON: String(values.p4Boost),
                WD: String(values.p4WaterDelay),
                WON: String(values.p4WaterOn),
              },
              "b.pump 5": {
                BON: String(values.p5Boost),
                WD: String(values.p5WaterDelay),
                WON: String(values.p5WaterOn),
              },
            },
            Water: String(values.Water),
          },
        };

        console.log("recipeObject", recipeObject);

        const key = Object.keys(recipeObject)[0];
        const prevRecipes = recipes;
        const newRecipes = {
          ...prevRecipes,
          [key]: recipeObject[key],
        };

        const recipeData = { data: { sizes: newRecipes } };

        const payload = {
          ID: recipeRes.id,
          Machine_Id: MachineId,
          Recipe_Data: JSON.stringify(recipeData),
        };

        // console.log("payload", payload);
        await SaveRecipe(payload);
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    console.log("recipes", recipes);
  }, [recipes]);

  return (
    <>
      {/* Loading */}
      {isLoading ? (
        <Row>
          <Col
            span={24}
            style={{
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 50,
                  }}
                  spin
                />
              }
            />
          </Col>
        </Row>
      ) : (
        // <>
        // <p>Under construction</p>
        // </>
        <>
          {/* size buttons */}
          <Row>
            <Flex gap="small" wrap>
              {sizeBoostData?.sizes.map((size) => (
                <Button
                  key={size.id}
                  type={
                    size?.title == currentRecipeTitle ? "primary" : "default"
                  }
                  onClick={(e) => recipeChangePressed(size.title)}
                >
                  {size.title}
                </Button>
              ))}
            </Flex>
          </Row>

          <Row className="mt-2">
            <Col span={24}>
              <Alert
                message="Please save the changes before moving to the other sizes"
                type="info"
              />
            </Col>
          </Row>

          {/* recipe settings */}
          <Row>
            <Col span={20} className="mt-2">
              <Form onSubmit={formik.handleSubmit}>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      label="Water"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="Water"
                        style={{ width: "100%" }}
                        min="0"
                        max="500"
                        step="0.01"
                        value={formik.values.Water}
                        onChange={(value) =>
                          formik.setFieldValue("Water", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.Water && formik.errors.Water ? (
                        <div>{formik.errors.Water}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item
                      label="Pump #1"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p1Boost"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p1Boost}
                        onChange={(value) =>
                          formik.setFieldValue("p1Boost", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p1Boost && formik.errors.p1Boost ? (
                        <div>{formik.errors.p1Boost}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Water Delay"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p1WaterDelay"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p1WaterDelay}
                        onChange={(value) =>
                          formik.setFieldValue("p1WaterDelay", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p1WaterDelay &&
                      formik.errors.p1WaterDelay ? (
                        <div>{formik.errors.p1WaterDelay}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Water On"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p1WaterOn"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p1WaterOn}
                        onChange={(value) =>
                          formik.setFieldValue("p1WaterOn", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p1WaterOn && formik.errors.p1WaterOn ? (
                        <div>{formik.errors.p1WaterOn}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item
                      label="Pump #2"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p2Boost"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p2Boost}
                        onChange={(value) =>
                          formik.setFieldValue("p2Boost", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p2Boost && formik.errors.p2Boost ? (
                        <div>{formik.errors.p2Boost}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Water Delay"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p2WaterDelay"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p2WaterDelay}
                        onChange={(value) =>
                          formik.setFieldValue("p2WaterDelay", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p2WaterDelay &&
                      formik.errors.p2WaterDelay ? (
                        <div>{formik.errors.p2WaterDelay}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Water On"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p2WaterOn"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p2WaterOn}
                        onChange={(value) =>
                          formik.setFieldValue("p2WaterOn", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p2WaterOn && formik.errors.p2WaterOn ? (
                        <div>{formik.errors.p2WaterOn}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item
                      label="Pump #3"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p3Boost"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p3Boost}
                        onChange={(value) =>
                          formik.setFieldValue("p3Boost", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p3Boost && formik.errors.p3Boost ? (
                        <div>{formik.errors.p3Boost}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Water Delay"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p3WaterDelay"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p3WaterDelay}
                        onChange={(value) =>
                          formik.setFieldValue("p3WaterDelay", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p3WaterDelay &&
                      formik.errors.p3WaterDelay ? (
                        <div>{formik.errors.p3WaterDelay}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Water On"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p3WaterOn"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p3WaterOn}
                        onChange={(value) =>
                          formik.setFieldValue("p3WaterOn", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p3WaterOn && formik.errors.p3WaterOn ? (
                        <div>{formik.errors.p3WaterOn}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item
                      label="Pump #4"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p4Boost"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p4Boost}
                        onChange={(value) =>
                          formik.setFieldValue("p4Boost", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p4Boost && formik.errors.p4Boost ? (
                        <div>{formik.errors.p4Boost}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Water Delay"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p4WaterDelay"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p4WaterDelay}
                        onChange={(value) =>
                          formik.setFieldValue("p4WaterDelay", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p4WaterDelay &&
                      formik.errors.p4WaterDelay ? (
                        <div>{formik.errors.p4WaterDelay}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Water On"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p4WaterOn"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p4WaterOn}
                        onChange={(value) =>
                          formik.setFieldValue("p4WaterOn", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p4WaterOn && formik.errors.p4WaterOn ? (
                        <div>{formik.errors.p4WaterOn}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item
                      label="Pump #5"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p5Boost"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p5Boost}
                        onChange={(value) =>
                          formik.setFieldValue("p5Boost", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p5Boost && formik.errors.p5Boost ? (
                        <div>{formik.errors.p5Boost}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Water Delay"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p5WaterDelay"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p5WaterDelay}
                        onChange={(value) =>
                          formik.setFieldValue("p5WaterDelay", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p5WaterDelay &&
                      formik.errors.p5WaterDelay ? (
                        <div>{formik.errors.p5WaterDelay}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Water On"
                      required={true}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber
                        name="p5WaterOn"
                        style={{ width: "100%" }}
                        min="0"
                        max="100000"
                        step="0.01"
                        value={formik.values.p5WaterOn}
                        onChange={(value) =>
                          formik.setFieldValue("p5WaterOn", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.p5WaterOn && formik.errors.p5WaterOn ? (
                        <div>{formik.errors.p5WaterOn}</div>
                      ) : null}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item
                      className="mt-2"
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={formik.submitForm}
                        disabled={!formik.dirty}
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default EditRecipes;
