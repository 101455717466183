import React, { useEffect, useState } from "react"
import { Table, Col, Row, Button, Spin, Space, Popconfirm, DatePicker, Input } from "antd"
import * as config from "../../Config/config"

import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons"
import { Link, useNavigate } from "react-router-dom"
import PageLayout from "../Layouts/PageLayout"
import moment from "moment";

const Index = () => {
  const navigate = useNavigate()
  const [datalist, setDataList] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  const [sortName, setSortName] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 25; // Number of items per page
  let pageChange=false;



  useEffect(() => {
    LoadData();
  }, [startDate, endDate, search, sortName, sortDirection, currentPage]);

  const LoadData = () => {
    setIsLoading(true);
    const params = {
      fromdate: startDate ? startDate.format("YYYY-MM-DDTHH:mm:ss") : null,
      todate: endDate ? endDate.format("YYYY-MM-DDTHH:mm:ss") : null,
      search: search,
      sortName: sortName,
      sortDirection: sortDirection,
      pageindex: currentPage,
      pageSize: pageSize,

    };
    console.log("Params sent to API:", params); // Check if search term is correct here


    config.GET_Req(config.GET_TRANSACTIONS, params)
      .then((response) => {
        console.log(response);
        setDataList(response.transactions);
        setTotalRows(response.totalRows);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };




  const DeleteItem = (itemId) => {
    // setIsLoading(true)
    // config
    //   .DELETE_Req(`${config.DELETE_SIZE}/${itemId}`, {})
    //   .then(function (response) {
    //     console.log(response)
    //     LoadData()
    //     setIsLoading(false)
    //   })
    //   .catch(function (err) {
    //     setIsLoading(false)
    //   })
    //   .finally(() => {
    //     setIsLoading(false)
    //   })
  }

  useEffect(() => {
    let isMounted = true

    LoadData()

    return () => {
      isMounted = false
    }
  }, [])

  const handleFromDateChange = (date) => {
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    setEndDate(date);
  };

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
  };





  const handleSort = (pagination, filters, sorter) => {
    debugger;
    const { field, order } = sorter;
    // Call only when sort button is selected for column
   if(sorter.column != undefined && pageChange==false)
    {
 let newSortDirection =sortDirection;
 
 switch(newSortDirection)
 {
case "ASC":
  newSortDirection = "DESC";
  break;
  case "DESC":  
  newSortDirection = "ASC";
  break;
  case "":  
  newSortDirection = "ASC";
  break;
  default:
    newSortDirection = "";
    break;
 }
 ;
    console.log('newSortDirection', newSortDirection);
    setSortName(field);
    setSortDirection(newSortDirection);
    }

    LoadData();

  };

  const handleClick = () => {
    LoadData();
  };


  const handlePageChange = (page) => {
    pageChange=true;
    setCurrentPage(page);
  };

  const columns = [
    {
      title: "Machine",
      dataIndex: "machineName",
      key: "machineName",
      sorter: true,
      sortOrder: sortName === 'machineName' && sortDirection,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      sortOrder: sortName === 'amount' && sortDirection,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      sortOrder: sortName === 'status' && sortDirection,
    },
    {
      title: "Device",
      dataIndex: "device",
      key: "device",
      sorter: true,
      sortOrder: sortName === 'device' && sortDirection,
      render: (text) => text || "Mobile",
    },
    {
      title: "Method",
      dataIndex: "paymentType",
      key: "paymentType",
      sorter: true,
      sortOrder: sortName === 'paymentType' && sortDirection,

    },
    {
      title: "Date & Time",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (text) => <span>{moment(text).format("MM/DD/YYYY HH:mm:ss")}</span>,
      sorter: true,
      sortOrder: sortName === 'paymentDate' && sortDirection,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button onClick={() => navigate(`/sales/edit/${record.id}`)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this record?"
              onConfirm={() => DeleteItem(record.id)}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button danger style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]


  return (
    <>
      <PageLayout>
        <Row>
          <h1>Sales</h1>
        </Row>
        <Row justify="end">
          <Col>
            <Space>
              <label>From</label>
              <DatePicker
                placeholder="From Date"
                onChange={handleFromDateChange}
                value={startDate}
                format="MM/DD/YYYY"
                allowClear={false}
                style={{ width: '200px', height: '40px' }}

              />
              <label>To</label>
              <DatePicker
                placeholder="To Date"
                onChange={handleToDateChange}
                value={endDate}
                format="MM/DD/YYYY"
                allowClear={false}
                style={{ width: '200px', height: '40px' }}

              />
              <label>Filter by Search</label>
              <div className="react-datepicker-wrapper" style={{ width: '200px', height: '40px' }}>
                <Input
                  type="text"
                  className="w-auto"
                  placeholder="Search Machine or Status"
                  value={search}
                  onChange={handleSearchInputChange}
                  style={{ width: '100%', height: '100%' }}

                />
              </div>
              <Button
                className="btn btn-primary btn-dark"
                onClick={handleClick} style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  minWidth: '80px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                Apply
              </Button>

            </Space>

          </Col>
        </Row>
        {/* <br></br> */}
        {/* <hr></hr> */}

        {/* Loading */}
        {isLoading ? (
          <Row>
            <Col
              span={24}
              style={{
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 50,
                    }}
                    spin
                  />
                }
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col
                span={24}
                style={{
                  padding: 10,
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "right",
                }}
              >
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table dataSource={datalist} columns={columns} rowKey="id" onChange={handleSort}
                  pagination={{
                    total: totalRows,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    onChange: handlePageChange,
                  }} />
              </Col>
            </Row>
          </>
        )}
      </PageLayout>
    </>
  )
}

export default Index
