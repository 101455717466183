import React, { useState } from "react"
import { Button, Form, Grid, Input, theme, Typography } from "antd"
import { LockOutlined, MailOutlined } from "@ant-design/icons"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import * as config from "../../Config/config"
import { useNavigate, useParams } from "react-router-dom"
import { useAuth } from "../Auth/AuthContext"

const { useToken } = theme
const { useBreakpoint } = Grid
const { Text, Title } = Typography

const LoginPage = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isMounted, setIsMounted] = useState(true)
  const [error, setError] = useState()
  const { login } = useAuth()

  const { token } = useToken()
  const screens = useBreakpoint()
  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  }

  const onFinish = (values) => {
    console.log("Received values of form: ", values)
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Please input your Email!"),
      password: Yup.string().required("Please input your Password!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log("Form values", values)
      // Handle form submission
      setSubmitting(true)
      try {
        SendLogin(values)
      } catch (error) {
        console.error("Error occurred:", error)
      } finally {
        // Make sure to set submitting to false, if applicable
        setSubmitting(false)
      }
    },
  })

  const SendLogin = (payload) => {
    if (!isMounted) return

    setIsLoading(true)
    config
      .POST_Req(`${config.LOGIN_URI}`, payload)
      .then(function (response) {
        console.log(response)
        if (response?.status) {
          login(response?.loggedInUserName, response?.loggedInUserId, response?.loggedInUserRole?.roleID)
          config.openNotification("success", "Login successfully")
          // navigate("/machines")
        } else {
          setError(response)
        }

        setIsLoading(false)
      })
      .catch(function (err) {
        console.log(err, err.message)
        setIsLoading(false)
      })
  }


  return (
    <div>
      <div style={styles.header}>
        <Title style={styles.title}>DrinkUp Dashboard</Title>
        <Text style={styles.text}>
          Please enter your details below to sign in.
        </Text>
        <br />
        {error ? (
          <Text style={{ color: "#ff0000" }}>{error?.message}</Text>
        ) : null}
      </div>
      <Form
        name="normal_login"
        layout="vertical"
        requiredMark="optional"
        onFinish={formik.handleSubmit}
      >
        <Form.Item
          validateStatus={
            formik.errors.email && formik.touched.email
              ? "error"
              : error?.status == false
              ? "warning"
              : ""
          }
          help={
            formik.errors.email && formik.touched.email
              ? formik.errors.email
              : ""
          }
        >
          <Input
            prefix={<MailOutlined />}
            placeholder="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <Form.Item
          validateStatus={
            formik.errors.password && formik.touched.password && error
              ? "error"
              : error?.status == false
              ? "warning"
              : ""
          }
          help={
            formik.errors.password && formik.touched.password
              ? formik.errors.password
              : ""
          }
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: "0px" }}>
          <Button
            block="true"
            type="primary"
            htmlType="submit"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default LoginPage
