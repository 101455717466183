import React from "react";
import { Grid,theme } from "antd";
import { Outlet } from "react-router-dom";

const { useToken } = theme;
const { useBreakpoint } = Grid;


const AuthLayout = () => {

  const { token } = useToken();
  const screens = useBreakpoint();
  const styles = {
      container: {
        margin: "0 auto",
        padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
        width: "380px"
      },
      footer: {
        marginTop: token.marginLG,
        textAlign: "center",
        width: "100%"
      },
      forgotPassword: {
        float: "right"
      },
      header: {
        marginBottom: token.marginXL
      },
      section: {
        alignItems: "center",
        backgroundColor: token.colorBgContainer,
        display: "flex",
        height: screens.sm ? "100vh" : "auto",
        padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
      },
      text: {
        color: token.colorTextSecondary
      },
      title: {
        fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
      }
    };

  return (
    <>
    <section style={styles.section}>
      <div style={styles.container}>
              <Outlet />
      </div>
    </section>
    </>
  );
}

export default AuthLayout;
