import React, { useEffect, useState } from "react";
import { Col, Row, Button, Spin, Checkbox, Form, Input, Tooltip } from "antd";
import * as config from "../../Config/config";
// import { Form, FormGroup, Input, Label } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineQuestionCircle  } from "react-icons/ai";
import PageLayout from "../Layouts/PageLayout";
import { useAuth } from "../Auth/AuthContext";

const Index = () => {
  const navigate = useNavigate();
  const { userName, userId } = useAuth();
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [activeRoles, setActiveRoles] = useState([]);

  useEffect(() => {
    LoadUserRoles();
    LoadData();
    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  const LoadData = async () => {
    if (!isMounted) return;

    setIsLoading(true);
    try {
      const userId = localStorage.getItem("userId")
      const response = await config.GET_Req(
        `${config.GET_USER_BY_ID}/${userId}`,
        {}
      );
      console.log(response);
      setUserData(response);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const LoadUserRoles = async () => {
    // Assume this function loads user roles
    // setActiveRoles(await fetchRoles());

    if (!isMounted) return;

    // setIsLoading(true);
    config
      .GET_Req(`${config.GET_USER_ROLES}`, {})
      .then(function (response) {
        console.log(response);
        setActiveRoles(response);
        // setIsLoading(false);
      })
      .catch(function (err) {
        // setIsLoading(false);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userData?.name || "",
      email: userData?.email || "",
      address: userData?.address || "",
      mobile: userData?.phoneNumber || "",
      password: "",
      password_confirm: "",
      city: userData?.city || "",
      state: userData?.state || "",
      zip: userData?.zipCode || "",
      enabled: userData?.isEnabled || true,
      roles: [], // assuming roles is an array of selected role IDs
      accountType: findRoleNameById(userData?.roles[0])
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      password: Yup.string()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
          "Password must contain atleast 1 lowercase, 1 uppercase, 1 number and 1 special character."
        )
        .min(8, "Password must have atleast 8 Charaters."),
      password_confirm: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const params = {
          userId: userData?.userId,
          name: values.name,
          email: userData?.email,
          password: "",
          phoneNumber: values.mobile,
          address: values.address,
          city: values.city,
          state: values.state,
          zipCode: values.zip,
          roles: userData?.roles,
          isenabled: userData?.isEnabled,
          createdby: 1,
        };
        console.log(params);
        await SaveData(params);
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const SaveData = (payload) => {
    if (!isMounted) return;

    setIsLoading(true);
    config
      .POST_Req(config.USER_ADD, payload)
      .then(function (response) {
        console.log(response);
        setIsLoading(false);
        if (response.status) {
          config.openNotification("success", "Record saved successfully");
        } else {
          config.openNotification("error", `Error`, response.message);
        }
      })
      .catch(function (err) {
        setIsLoading(false);
      });
  };

  function findRoleNameById(id) {
    // Find the object in activeRoles array with matching id
    const role = activeRoles.find(role => role.id === id);
    // If role is found, return its roleName; otherwise return null or handle as needed
    return role?.roleName;
}

  return (
    <>
      <PageLayout>
        <Row>
          <Col span={12}>
            <h1>Account</h1>
          </Col>
          <Col
            span={12}
            style={{
              padding: 10,
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
            }}
          >
          </Col>
        </Row>
        <hr></hr>
        {/* Loading */}
        {isLoading ? (
          <Row>
            <Col
              span={24}
              style={{
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 50,
                    }}
                    spin
                  />
                }
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col span={20}>
                <Form onFinish={formik.handleSubmit}>
                  <Form.Item
                    label="Name"
                    required={true}
                    // validateStatus={
                    //   formik.errors.name && formik.touched.name
                    //     ? "error"
                    //     : ""
                    // }
                    // help={
                    //   formik.errors.name && formik.touched.name
                    //     ? formik.errors.name
                    //     : ""
                    // }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    validateStatus={
                      formik.errors.email && formik.touched.email ? "error" : ""
                    }
                    help={
                      formik.errors.email && formik.touched.email
                        ? formik.errors.email
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      disabled
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Address"
                    validateStatus={
                      formik.errors.address && formik.touched.address
                        ? "error"
                        : ""
                    }
                    help={
                      formik.errors.address && formik.touched.address
                        ? formik.errors.address
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input.TextArea
                      placeholder="Address"
                      name="address"
                      autoComplete="off"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mobile"
                    validateStatus={
                      formik.errors.mobile && formik.touched.mobile
                        ? "error"
                        : ""
                    }
                    help={
                      formik.errors.mobile && formik.touched.mobile
                        ? formik.errors.mobile
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input
                      placeholder="Mobile Number"
                      name="mobile"
                      autoComplete="off"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="City"
                    validateStatus={
                      formik.errors.city && formik.touched.city ? "error" : ""
                    }
                    help={
                      formik.errors.city && formik.touched.city
                        ? formik.errors.city
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input
                      placeholder="City"
                      name="city"
                      autoComplete="off"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="State"
                    validateStatus={
                      formik.errors.state && formik.touched.state ? "error" : ""
                    }
                    help={
                      formik.errors.state && formik.touched.state
                        ? formik.errors.state
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input
                      placeholder="State"
                      name="state"
                      autoComplete="off"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Zip Code"
                    validateStatus={
                      formik.errors.zip && formik.touched.zip ? "error" : ""
                    }
                    help={
                      formik.errors.zip && formik.touched.zip
                        ? formik.errors.zip
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input
                      placeholder="Zip Code"
                      name="zip"
                      maxLength={6}
                      autoComplete="off"
                      value={formik.values.zip}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  {/* Add roles checkboxes */}
                  <Form.Item
                    label="Account Type"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                    validateStatus={
                      formik.errors.accountType && formik.touched.accountType ? "error" : ""
                    }
                    help={
                      formik.errors.accountType && formik.touched.accountType
                        ? formik.errors.accountType
                        : ""
                    }
                  >
                    
                    <Input
                      name="accountType"
                      disabled
                      maxLength={6}
                      autoComplete="off"
                      value={formik.values.accountType}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </PageLayout>
    </>
  );
};
export default Index;