import React, { useEffect, useState } from "react"
import { Col, Row, Button, Spin, Checkbox, Form, Input, Select, Tabs } from "antd"
import * as config from "../../Config/config"
import { LoadingOutlined, CopyOutlined } from "@ant-design/icons"
import { useNavigate, useParams } from "react-router-dom"
import PageLayout from "../Layouts/PageLayout"
import EditMachinePage from "./EditTabs/MachineDetails"
import EditRecipesPage from "./EditTabs/EditRecipes";

const Edit = () => {
  const { MachineId, tabId } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(null)

  // Update active tab based on tabId from URL
  useEffect(() => {
    setActiveTab(parseInt(tabId)) // Assuming tabId is a string, convert it to integer
  }, [tabId])

  const handleTabChange = (key) => {
    setActiveTab(key)
    navigate(`/machines/edit/${MachineId}/${key}`) // Update the URL
  }

  return (
    <>
      <PageLayout>
        <Row>
          <Col span={12}>
            <h1>Edit Machine</h1>
          </Col>
          <Col
            span={12}
            style={{
              padding: 10,
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
            }}
          >
            <Button onClick={() => navigate("/machines")}>Back</Button>
          </Col>
        </Row>
        {/* <hr></hr> */}
        {/* Loading */}
        {isLoading ? (
          <Row>
            <Col
              span={24}
              style={{
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 50,
                    }}
                    spin
                  />
                }
              />
            </Col>
          </Row>
        ) : (
          <>
            <Tabs
              activeKey={activeTab}
              onChange={handleTabChange}
              type="card"
              items={[
                {
                  label: `Machine Details`,
                  key: 1,
                  children: <EditMachinePage />,
                },
                {
                  label: `Recipes`,
                  key: 2,
                  children: <EditRecipesPage />,
                },
              ]}
            />
          </>
        )}
      </PageLayout>
    </>
  )
}

export default Edit
