import React, { useEffect, useState } from "react";
import { Col, Row, Button, Spin, Checkbox, Form, Input, Select } from "antd";
import * as config from "../../../Config/config";
// import { Form, FormGroup, Input, Label } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import { LoadingOutlined, CopyOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import "./machine.css";

const Index = () => {
  const { MachineId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [allSizes, setAllSizes] = useState([]);
  const [allBoosts, setAllBoosts] = useState([]);
  const [copied, setCopied] = useState(false);
  const [machineGUID, setMachineGUID] = useState();
  const [machineData, setMachineData] = useState();
  const [orgBoostList, setOrgBoostList] = useState()

  useEffect(() => {
    LoadMachine();
    LoadSizes();
    LoadBoosts();

    return () => {
      setIsMounted(false); // Set isMounted to false when component unmounts
    };
  }, []);

  const LoadMachine = () => {
    setIsLoading(true);
    config
      .GET_Req(`${config.SELECT_MACHINE}/${MachineId}`, {})
      .then(function (response) {
        console.log("--------------- -----");
        console.log(response);
        console.log(response?.boosts);
        setOrgBoostList(response?.boosts);
        let tempRes = response;
        const formatedBoosts = reverseFormattedBoosts(tempRes.boosts);
        tempRes.boosts = formatedBoosts;
        setMachineData(tempRes);
        setIsLoading(false);
      })
      .catch(function (err) {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reverseFormattedBoosts = (boosts) => {
    let emptyBoostsList = ["", "", "", "", ""];

    boosts.slice(1).forEach((boostRecord, index) => {
      emptyBoostsList[index] = boostRecord.id;
    });
    return emptyBoostsList;
  };

  const LoadSizes = () => {
    // setIsLoading(true)
    config
      .GET_Req(config.GET_SIZES, {})
      .then(function (response) {
        console.log("sizes:", response);
        setAllSizes(response);
        // setIsLoading(false)
      })
      .catch(function (err) {
        // setIsLoading(false)
      })
      .finally(() => {
        // setIsLoading(false)
      });
  };

  const LoadBoosts = () => {
    // setIsLoading(true)
    config
      .GET_Req(config.GET_BOOSTS, {})
      .then(function (response) {
        console.log("all boosts:", response);
        setAllBoosts(response);
        // setIsLoading(false)
      })
      .catch(function (err) {
        // setIsLoading(false)
      })
      .finally(() => {
        // setIsLoading(false)
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: MachineId || "",
      name: machineData?.machineDetails?.name || "",
      location: machineData?.machineDetails?.location || "",
      description: machineData?.machineDetails?.description || "",
      initializationToken: MachineId || "",
      sizes: machineData?.sizes || null,
      boosts: machineData?.boosts || ["", "", "", "", ""],
      changedby: "",
    },
    validationSchema: Yup.object().shape({
      id: Yup.string().required("ID is required"),
      name: Yup.string().required("Machine name is required"),
      location: Yup.string(),
      description: Yup.string(),
      initializationToken: Yup.string().required("Token is required"),
      sizes: null,
      boosts: null,
      changedby: "",
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values);
      try {
        setSubmitting(true);
        const params = {
          ID: values.id,
          Name: values.name,
          Location: values.location,
          InitializationToken: values.id,
          Description: values.description,
          Sizes: values.sizes,
          boosts: formattedBoosts(values.boosts),
          VersionNumber: "",
          Changedby: "dilina@vitruvian-engineering.com",
        };

        console.log("saving: ", params);
        await SaveData(params)
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        // Make sure to set submitting to false, if applicable
        setSubmitting(false);
      }
    },
  });

  const SaveData = async (payload) => {
    if (!isMounted) return;

    setIsSaving(true);
    await config
      .PUT_Req(`${config.EDIT_MACHINE}/${MachineId}`, payload)
      .then(function (response) {
        console.log(response);
        config.openNotification("success", "Record saved successfully");
        // navigate("/machines")
        setIsSaving(false);
        // if (response.success) {
        //   config.openNotification("success", "Record saved successfully")
        //   navigate("/machines")
        // } else {
        //   config.openNotification("error", `Error`, response.message)
        // }
      })
      .catch(function (err) {
        console.error(err);
        setIsSaving(false);
      });
  };

  const formattedBoosts = (boosts) => {
    console.log("Before Formatted boosts: ", boosts);
    console.log("All Boosts: ", orgBoostList);

    let tempBoost = [];

    // Iterate through boosts and format them into tempBoost
    for (let index = 0; index < 5; index++) {
      if (boosts[index] !== undefined && boosts[index] !== "") {
        const boostItem = {
          ID: boosts[index],
          Position: (index+1).toString(),
        };

        tempBoost.push(boostItem);
      }
    }

    // Add back the first item from allBoosts to the first position in tempBoost
    if (orgBoostList[0]) {
      const firstBoostItem = {
        ID: orgBoostList[0].id, // Assuming allBoosts has a property 'id'
        Position: "0", // Assuming the first position should be '0'
      };
      tempBoost.unshift(firstBoostItem); // Insert at the beginning of tempBoost
    }

    console.log("Formatted boosts: ", tempBoost);
    return tempBoost;
  };

  const GenerateGUID = () => {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    const guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    setMachineGUID(guid);
  };

  return (
    <>
      {/* Loading */}
      {isLoading ? (
        <Row>
          <Col
            span={24}
            style={{
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 50,
                  }}
                  spin
                />
              }
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col span={20}>
              <Form onFinish={formik.handleSubmit}>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Machine GUID"
                      validateStatus={
                        formik.errors.initializationToken &&
                        formik.touched.initializationToken
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.initializationToken &&
                        formik.touched.initializationToken
                          ? formik.errors.initializationToken
                          : ""
                      }
                    >
                      <Input
                        readOnly
                        status="warning"
                        name="initializationToken"
                        placeholder="Enter Initialization Token"
                        value={formik.values.initializationToken}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        suffix={
                          <Button
                            type="text"
                            icon={<CopyOutlined />}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                formik.values.initializationToken
                              );
                              setCopied(true);
                              setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
                            }}
                          >
                            {copied ? "Copied!" : ""}
                          </Button>
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Machine Name"
                      validateStatus={
                        formik.errors.name && formik.touched.name ? "error" : ""
                      }
                      help={
                        formik.errors.name && formik.touched.name
                          ? formik.errors.name
                          : ""
                      }
                    >
                      <Input
                        name="name"
                        placeholder="Enter Machine Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="nope"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Location"
                      validateStatus={
                        formik.errors.location && formik.touched.location
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.location && formik.touched.location
                          ? formik.errors.location
                          : ""
                      }
                    >
                      <Input
                        name="location"
                        placeholder="Enter Location"
                        value={formik.values.location}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="nope"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Description">
                      <Input.TextArea
                        rows={2}
                        name="description"
                        placeholder="Enter Description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Choose Sizes *" required>
                      <Checkbox.Group
                        value={formik.values.sizes}
                        onChange={(values) =>
                          formik.setFieldValue("sizes", values)
                        }
                      >
                        <Row>
                          {allSizes?.map((size, index) => (
                            <Col key={index} span={24}>
                              <Checkbox value={size.id}>
                                {size.capacity} ({size.title})
                              </Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    {[...Array(5)]?.map((_, i) => (
                      <div className="row" key={i}>
                        <div className="col-md-12">
                          <Form.Item label={`Pump ${i + 1} :`} required>
                            <Select
                              className="drpMachinePosition"
                              value={formik.values.boosts?.[i] || "0"} // Add optional chaining here
                              onChange={(value) =>
                                formik.setFieldValue(`boosts.${i}`, value)
                              }
                            >
                              <Select.Option key="0" value="0">
                                Select Boost
                              </Select.Option>
                              {allBoosts.map((boost, index) => (
                                <Select.Option
                                  key={boost.target}
                                  value={boost.id}
                                >
                                  {/* Use a unique key */}
                                  {boost.title}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <label className="BoostNotes" id={`Comment${i + 1}`}>
                          {formik.values.commentsMap?.[`Comment${i + 1}`] || ""}
                        </label>
                      </div>
                    ))}
                  </Col>
                </Row>

                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                >
                  Save
                </Button>
              </Form>

              {/* <Form>
                <FormGroup>
                  <Label for="exampleEmail">Email</Label>
                  <Input
                    id="exampleEmail"
                    name="email"
                    placeholder="with a placeholder"
                    type="email"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword">Password</Label>
                  <Input
                    id="examplePassword"
                    name="password"
                    placeholder="password placeholder"
                    type="password"
                  />
                </FormGroup>
                <Button type="primary">Save</Button>
              </Form> */}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Index;
