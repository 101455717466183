import React, { useEffect, useState } from "react";
import { Col, Row, Button, Spin, Checkbox } from "antd";
import * as config from "../../Config/config";
// import { Form, FormGroup, Input, Label } from "reactstrap";
import { Form, Input, Upload } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PageLayout from "../Layouts/PageLayout";

const Index = () => {
  const { SizeId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    LoadData();

    return () => {
      setIsMounted(false); // Set isMounted to false when component unmounts
    };
  }, []);

  const LoadData = () => {
    if (!isMounted) return;
    console.log(SizeId);

    setIsLoading(true);
    config
      .GET_Req(`${config.SELECT_SIZE}/${SizeId}`, {})
      .then(function (response) {
        console.log(response);
        setData(response);
        setIsLoading(false);
      })
      .catch(function (err) {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: data?.title || "",
      price: data?.price / 100 || 0,
      capacity: data?.capacity || 0,
      target: data?.target || 0,
      enabled: data?.enabled || false,
      image: data?.image || "",
      mediaID: data?.mediaID || "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      price: Yup.number().required("Price is required"),
      capacity: Yup.number().required("Capacity is required"),
      target: Yup.number().required("Sort No is required"),
      image: Yup.mixed()
        .nullable()
        .test("image", "Image is required", (value) => {
          return !!value; // Pass validation if value is truthy (not null or undefined)
        })
        .test(
          "fileSize",
          "Image must be less than or equal to 300x300 pixels",
          async function (value) {
            if (!value) return true; // No image provided, validation will be handled by the "required" rule

            // Check if value is a file object (object) or a URL string (string)
            const isFileObject = typeof value !== "string";
            if (!isFileObject) return true; // URL strings are considered valid

            // Load and validate the dimensions of the image
            return new Promise((resolve) => {
              const image = new Image();
              image.onload = () => {
                if (image.width <= 300 && image.height <= 300) {
                  resolve(true); // Image dimensions are within the specified limits
                } else {
                  resolve(false); // Image dimensions exceed the limits
                }
                URL.revokeObjectURL(image.src); // Clean up object URL after loading
              };
              image.src = URL.createObjectURL(value); // Set image source for file object
            });
          }
        ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // Handle form submission here
        if (typeof values.image != "string") {
          const response = await uploadImageFile(values);
          const params = {
            id: SizeId,
            title: values.title,
            price: parseInt(values.price * 100),
            capacity: parseInt(values.capacity),
            enabled: values.enabled,
            mediaId: response.mediaID,
            target: parseInt(values.target),
          };
          console.log("with selecting an image: ", params);
          SaveData(params);
        } else {
          const params = {
            id: SizeId,
            title: values.title,
            price: parseInt(values.price * 100),
            capacity: parseInt(values.capacity),
            enabled: values.enabled,
            mediaId: values.mediaID,
            target: parseInt(values.target),
          };
          console.log("without selecting an image: ", params);
          SaveData(params);
        }
        setSubmitting(false);
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        // Make sure to set submitting to false, if applicable
        setSubmitting(false);
      }
    },
  });

  const SaveData = (payload) => {
    if (!isMounted) return;

    setIsLoading(true);
    config
      .PUT_Req(`${config.UPDATE_SIZE}/${SizeId}`, payload)
      .then(function (response) {
        console.log(response);
        setIsLoading(false);
        config.openNotification("success", "Record updated successfully");
        navigate("/sizes");
      })
      .catch(function (err) {
        console.log(err, err.message);
        setIsLoading(false);
      });

    // axios.put(
    //   `${config.UPDATE_SIZE}/${SizeId}`,
    //   payload
    // ).then(function (response) {
    //   console.log(response)
    // }).catch(function(err){
    //   console.log(err)
    // })
  };

  const uploadImageFile = async (values) => {
    if (!isMounted) return;

    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("File", values.image);
      formData.append("Page", "Sizes");
      formData.append("Title", values.title);
      formData.append("ID", SizeId);

      const response = await axios.post(`${config.UPLOAD_BOOSTS}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);
      setIsUploading(false);
      return response.data;
    } catch (error) {
      setIsUploading(false);
      config.openNotification("error", "Image Upload Failed");
      throw error;
    }
  };

  const handleFileUpload = (file) => {
    if (!isMounted) return;
    const fileSize = file.size / 1024 / 1024; // in MB
    if (fileSize > 2) {
      // message.error("Image must be less than 2MB");
      config.openNotification(
        "error",
        "Upload Error",
        "Image must be less than 2MB"
      );
      return false;
    }
    formik.setFieldValue("image", file);
    return false; // Prevent upload
  };

  const getImageUrl = (fileName) => {
    return `${config.MEDIA_LOCATION}/${fileName}`;
  };

  return (
    <>
    <PageLayout>
      <Row>
        <Col span={12}>
          <h1>Edit Size</h1>
        </Col>
        <Col
          span={12}
          style={{
            padding: 10,
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
          }}
        >
          <Button onClick={() => navigate("/sizes")}>Back</Button>
        </Col>
      </Row>
      <hr></hr>
      {/* Loading */}
      {isLoading ? (
        <Row>
          <Col
            span={24}
            style={{
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 50,
                  }}
                  spin
                />
              }
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col span={20}>
              <Form onFinish={formik.handleSubmit}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Title *"
                      validateStatus={
                        formik.errors.title && formik.touched.title
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.title && formik.touched.title
                          ? formik.errors.title
                          : ""
                      }
                    >
                      <Input
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="nope"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Price ($) *"
                      validateStatus={
                        formik.errors.price && formik.touched.price
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.price && formik.touched.price
                          ? formik.errors.price
                          : ""
                      }
                    >
                      <Input
                        name="price"
                        type="number"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Sort No"
                      validateStatus={
                        formik.errors.target && formik.touched.target
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.target && formik.touched.target
                          ? formik.errors.target
                          : ""
                      }
                    >
                      <Input
                        name="target"
                        type="number"
                        value={formik.values.target}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Capacity"
                      validateStatus={
                        formik.errors.target && formik.touched.target
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.target && formik.touched.target
                          ? formik.errors.target
                          : ""
                      }
                    >
                      <Input
                        name="capacity"
                        type="number"
                        value={formik.values.capacity}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Select Image (png)*"
                      validateStatus={
                        formik.errors.image && formik.touched.image
                          ? "error"
                          : ""
                      }
                      help={
                        formik.errors.image && formik.touched.image
                          ? formik.errors.image
                          : ""
                      }
                    >
                      <Upload
                        name="file"
                        accept="image/png"
                        beforeUpload={handleFileUpload}
                        listType="picture-card"
                        showUploadList={false}
                        className="avatar-uploader"
                      >
                        {formik.values.image ? (
                          typeof formik.values.image === "string" ? (
                            <img
                              src={getImageUrl(formik.values.image)} // Generate image URL
                              alt="Uploaded"
                              style={{ height: "100%" }}
                            />
                          ) : (
                            <img
                              src={URL.createObjectURL(formik.values.image)}
                              alt="Uploaded"
                              style={{ height: "100%" }}
                            />
                          )
                        ) : (
                          <button
                            style={{
                              border: 0,
                              background: "none",
                            }}
                            type="button"
                          >
                            {isUploading ? (
                              <LoadingOutlined />
                            ) : (
                              <PlusOutlined />
                            )}
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Upload
                            </div>
                          </button>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Enable">
                      <Checkbox
                        name="enabled"
                        checked={formik.values.enabled}
                        onChange={formik.handleChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                >
                  Save
                </Button>
              </Form>

              {/* <Form>
                <FormGroup>
                  <Label for="exampleEmail">Email</Label>
                  <Input
                    id="exampleEmail"
                    name="email"
                    placeholder="with a placeholder"
                    type="email"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword">Password</Label>
                  <Input
                    id="examplePassword"
                    name="password"
                    placeholder="password placeholder"
                    type="password"
                  />
                </FormGroup>
                <Button type="primary">Save</Button>
              </Form> */}
            </Col>
          </Row>
        </>
      )}
      </PageLayout>
    </>
  );
};

export default Index;
