import React, { useEffect, useState } from "react";
import { Table, Col, Row, Button, Spin, Space, Popconfirm } from "antd";
import * as config from "../../Config/config";

import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import PageLayout from "../Layouts/PageLayout";

const Index = () => {
  const navigate = useNavigate();
  const [datalist, setDataList] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const userLevel = localStorage.getItem("userLevel");

  const LoadData = () => {
    setIsLoading(true);
    config
      .GET_Req(config.GET_MACHINES, {})
      .then(function (response) {
        console.log(response);
        setDataList(response);
        setIsLoading(false);
      })
      .catch(function (err) {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const DeleteItem = (itemId) => {
    setIsLoading(true);
    config
      .DELETE_Req(`${config.DELETE_BOOSTS}/${itemId}`, {})
      .then(function (response) {
        console.log(response);
        LoadData();
        setIsLoading(false);
      })
      .catch(function (err) {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true;

    LoadData();

    return () => {
      isMounted = false;
    };
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Locations",
      dataIndex: "location",
      key: "location",
    },
    {
            title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (status ? 'Active' : 'Inactive'), 
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            
            <Button onClick={() => navigate(`/machines/edit/${record.id}/2`)}>
              Recipes
            </Button>


            <Button onClick={() => navigate(`/machines/edit/${record.id}/1`)}>
              Edit
            </Button>

            {userLevel < 2 ? (
              <Popconfirm
                title="Delete"
                description="Are you sure to delete this record?"
                onConfirm={() => DeleteItem(record.id)}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            ) : null}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <PageLayout>
        <Row>
          <h1>Machines</h1>
        </Row>

        <hr></hr>

        {/* Loading */}
        {isLoading ? (
          <Row>
            <Col
              span={24}
              style={{
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 50,
                    }}
                    spin
                  />
                }
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col
                span={24}
                style={{
                  padding: 10,
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "right",
                }}
              >
                {userLevel == 1 && userLevel == 2 ? (
                  <Button
                    color="success"
                    onClick={() => navigate(`/machines/add`)}
                  >
                    {" "}
                    Add New
                  </Button>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table dataSource={datalist} columns={columns} rowKey="id" />
              </Col>
            </Row>
          </>
        )}
      </PageLayout>
    </>
  );
};

export default Index;
