import React, { useEffect, useState } from "react";
import { Col, Row, Button, Spin, Checkbox } from "antd";
import * as config from "../../Config/config";
// import { Form, FormGroup, Input, Label } from "reactstrap";
import { Form, Input, Upload } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import FormItem from "antd/es/form/FormItem";
import axios from "axios";
import PageLayout from "../Layouts/PageLayout";

const Index = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [activeRoles, setActiveRoles] = useState([]);

  useEffect(() => {
    let isMounted = true;

    // LoadData()
    LoadUserRoles();
    return () => {
      setIsMounted(false); // Set isMounted to false when component unmounts
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      address: "",
      mobile: "",
      password: "",
      password_confirm: "",
      city: "",
      state: "",
      zip: "",
      enabled: true,
      roles: [], // assuming roles is an array of selected role IDs
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      roles: Yup.array()
        .min(1, "Role is required")
        .required("Role is required"),
      password: Yup.string()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
          "Password must contain atleast 1 lowercase, 1 uppercase, 1 number and 1 special character."
        )
        .min(8, "Password must have atleast 8 Charaters.")
        .required("Password cannot be empty."),
      password_confirm: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please confirm your password"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission

      try {
        const params = {
          userId: 0,
          name: values.name,
          email: values.email,
          password: values.password,
          phoneNumber: values.mobile,
          address: values.address,
          city: values.city,
          state: values.state,
          zipCode: values.zip,
          roles: values.roles,
          isenabled: values.enabled,
          createdby: 1,
        };
        console.log(params);
        SaveData(params);
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        // Make sure to set submitting to false, if applicable
        setSubmitting(false);
      }
    },
  });

  const SaveData = (payload) => {
    if (!isMounted) return;

    setIsLoading(true);
    config
      .POST_Req(config.USER_ADD, payload)
      .then(function (response) {
        console.log(response);
        setIsLoading(false);
        if (response.status) {
          config.openNotification("success", "Record saved successfully");
          navigate("/users");
        } else {
          config.openNotification("error", `Error`, response.message);
        }
      })
      .catch(function (err) {
        setIsLoading(false);
      });
  };

  const LoadUserRoles = () => {
    if (!isMounted) return;

    setIsLoading(true);
    config
      .GET_Req(`${config.GET_USER_ROLES}`, {})
      .then(function (response) {
        console.log(response);
        setActiveRoles(response);
        setIsLoading(false);
      })
      .catch(function (err) {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function generateRandomPassword(length) {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%?";
    let password = "";

    while (password.length < length) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    // Ensure the password meets the criteria
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}|[\]\\:";'<>?,./]/.test(password);

    if (hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar) {
      return password;
    } else {
      // If criteria not met, recursively generate a new password
      return generateRandomPassword(length);
    }
  }

  const generatePassword = () => {
    const pass = generateRandomPassword(8);

    formik.setValues({
      ...formik.values,
      password: pass,
      password_confirm: pass,
    });
  };

  return (
    <>
      <PageLayout>
        <Row>
          <Col span={12}>
            <h1>Add User</h1>
          </Col>
          <Col
            span={12}
            style={{
              padding: 10,
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
            }}
          >
            <Button onClick={() => navigate("/users")}>Back</Button>
          </Col>
        </Row>
        <hr></hr>
        {/* Loading */}
        {isLoading ? (
          <Row>
            <Col
              span={24}
              style={{
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 50,
                    }}
                    spin
                  />
                }
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col span={20}>
                <Form onFinish={formik.handleSubmit}>
                  <Form.Item
                    label="Name"
                    required={true}
                    validateStatus={
                      formik.errors.name && formik.touched.name ? "error" : ""
                    }
                    help={
                      formik.errors.name && formik.touched.name
                        ? formik.errors.name
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input
                      placeholder="Name"
                      name="name"
                      maxLength={30}
                      autoComplete="off"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    required={true}
                    validateStatus={
                      formik.errors.email && formik.touched.email ? "error" : ""
                    }
                    help={
                      formik.errors.email && formik.touched.email
                        ? formik.errors.email
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    required={true}
                    validateStatus={
                      formik.errors.password && formik.touched.password
                        ? "error"
                        : ""
                    }
                    help={
                      formik.errors.password && formik.touched.password
                        ? formik.errors.password
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input.Password
                      placeholder="Password"
                      name="password"
                      maxLength={30}
                      autoComplete="off"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    required={true}
                    validateStatus={
                      formik.errors.password_confirm && formik.touched.password_confirm
                        ? "error"
                        : ""
                    }
                    help={
                      formik.errors.password_confirm && formik.touched.password_confirm
                        ? formik.errors.password_confirm
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input.Password
                      placeholder="Re-enter the Password"
                      name="password_confirm"
                      maxLength={30}
                      autoComplete="off"
                      value={formik.values.password_confirm}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
                    <Button type="default" onClick={generatePassword}>
                      Generate Password
                    </Button>
                  </Form.Item>
                  <Form.Item
                    label="Address"
                    name="address"
                    validateStatus={
                      formik.errors.address && formik.touched.address
                        ? "error"
                        : ""
                    }
                    help={
                      formik.errors.address && formik.touched.address
                        ? formik.errors.address
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input.TextArea
                      placeholder="Address"
                      name="address"
                      autoComplete="off"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mobile"
                    name="mobile"
                    validateStatus={
                      formik.errors.mobile && formik.touched.mobile
                        ? "error"
                        : ""
                    }
                    help={
                      formik.errors.mobile && formik.touched.mobile
                        ? formik.errors.mobile
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input
                      placeholder="Mobile Number"
                      name="mobile"
                      autoComplete="off"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="City"
                    name="city"
                    validateStatus={
                      formik.errors.city && formik.touched.city ? "error" : ""
                    }
                    help={
                      formik.errors.city && formik.touched.city
                        ? formik.errors.city
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input
                      placeholder="City"
                      name="city"
                      autoComplete="off"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="State"
                    name="state"
                    validateStatus={
                      formik.errors.state && formik.touched.state ? "error" : ""
                    }
                    help={
                      formik.errors.state && formik.touched.state
                        ? formik.errors.state
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input
                      placeholder="State"
                      name="state"
                      autoComplete="off"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Zip Code"
                    name="zip"
                    validateStatus={
                      formik.errors.zip && formik.touched.zip ? "error" : ""
                    }
                    help={
                      formik.errors.zip && formik.touched.zip
                        ? formik.errors.zip
                        : ""
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Input
                      placeholder="Zip Code"
                      name="zip"
                      maxLength={6}
                      autoComplete="off"
                      value={formik.values.zip}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  {/* Add roles checkboxes */}
                  <Form.Item
                    label="Choose Role(s)"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                    required={true}
                    validateStatus={
                      formik.errors.roles && formik.touched.roles ? "error" : ""
                    }
                    help={
                      formik.errors.roles && formik.touched.roles
                        ? formik.errors.roles
                        : ""
                    }
                  >
                    {activeRoles && activeRoles.length > 0 ? (
                      activeRoles.map((role, index) => (
                        <Checkbox
                          key={role.id}
                          name={`roles[${index}]`}
                          checked={formik.values.roles.includes(role.id)}
                          onChange={(e) => {
                            const checkedRoles = [];
                            if (e.target.checked) {
                              checkedRoles.push(role.id);
                            }
                            formik.setFieldValue("roles", checkedRoles);
                          }}
                        >
                          {role.roleName}
                        </Checkbox>
                      ))
                    ) : (
                      <div className="dataNotFound">Please add roles !!!</div>
                    )}
                  </Form.Item>
                  <Form.Item
                    label="Enable User"
                    required={true}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <Checkbox
                      name="enabled"
                      checked={formik.values.enabled}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Add
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </PageLayout>
    </>
  );
};

export default Index;
