import React, { useEffect,useState } from "react";
import PageLayout from "../Layouts/PageLayout";
import * as config from "../../Config/config";
import { Table, Col, Row,Button, Space, Popconfirm} from "antd";
import { DeleteOutlined } from "@ant-design/icons";


function Index() {

const [datalist,setDataList]=useState();
const [isLoading, setIsLoading] = useState(true)
useEffect(() => {
  let isMounted = true

  LoadData();

  return () => {
    isMounted = false
  }
}, []);

const LoadData=()=>{
setIsLoading(true);
config.GET_Req(config.GET_OFFLINE_MACHINES,{})
.then(function (response){
  response.map(x=>x.lastSeenTime=formatDate(x.lastSeenTime));
  setDataList(response);
  setIsLoading(false);
})
.catch(function(err) {
setIsLoading(false);
})
.finally(()=>{
  setIsLoading(false);
})
}

const DeleteItem = (itemId) => {
  setIsLoading(true)
  config
    .DELETE_Req(`${config.REMOVE_OFFLINE_MACHINE}/${itemId}`, {})
    .then(function (response) {
      console.log(response)
      LoadData()
      setIsLoading(false)
    })
    .catch(function (err) {
      setIsLoading(false)
    })
    .finally(() => {
      setIsLoading(false)
    })
}

const formatDate=(lastSeen)=>{
  const day=new Date(lastSeen).getDate();
  const month=new Date(lastSeen).getMonth()+1;
  const year=new Date(lastSeen).getFullYear();
  const hours=new Date(lastSeen).getHours();
  const mins=new Date(lastSeen).getMinutes();
  const secs=new Date(lastSeen).getSeconds();
  return `${day}-${month}-${year} ${hours}:${mins}:${secs}`;
}


const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Machine Id",
    dataIndex: "machineId",
    key: "machineId"
  },
  {
    title: "Last Seen Time",
    dataIndex: "lastSeenTime",
    key: "lastSeenTime"
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    render: (text, record) => {
      return (
        <Space size="middle">
          
          <Popconfirm
            title="Delete"
            description="Are you sure to delete this record?"
            onConfirm={() => DeleteItem(record.id)}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      )
    },
  }
];

  return (
    <PageLayout>
      <Row>
          <h1>Offline machines</h1>
        </Row>

        <hr></hr>

      <Row>
      <Col span={24}>
      <Table dataSource={datalist} columns={columns} rowKey="id" />
      </Col>
      </Row>
    </PageLayout>
  );
}

export default Index;